import { API } from '@/api/Api'

export default {
  async Rate (data = {}, config = {}) {
    const Endpoint = API.Endpoint.Rating.Rate
    const Data = data
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  },

  async Get (data = {}, config = {}) {
    const Endpoint = API.Endpoint.Rating.Get
    const Data = data
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  }
}
