import { floatNumber } from '@/lib/helpers'
import ProductQuantityCommon from '@/mixins/product/productQuantityCommon'

export default {
  name  : 'productCommon',
  mixins: [ProductQuantityCommon],
  data () {
    return {
      selectedProduct: null
    }
  },
  computed: {
    productTotal () {
      if (this.selectedProduct.UnitId > 1) return floatNumber(this.selectedProduct.Price)

      return floatNumber(this.quantity * floatNumber(this.selectedProduct.Price))
    }
  },
  methods: {
    initProduct () {
      const product = this.selectedProduct

      if (!product.DefaultUnitQuantity) product.DefaultUnitQuantity = product.UnitQuantity
      if (!product.OriginalUnitPrice) product.OriginalUnitPrice = product.Price

      if (product.UnitId > 1) {
        product.UnitQuantity = product.MinimumQuantity > this.unitQuantity ? product.MinimumQuantity : this.unitQuantity
      } else {
        product.Quantity = product.MinimumQuantity > this.quantity ? product.MinimumQuantity : this.quantity
      }

      this.onRadioChange()
      this.onCheckBoxChange()
    },

    onRadioClick (option, value) {
      option.DefaultValueId = value.Id
      this.onRadioChange()
    },
    onRadioChange () {
      if (!this.selectedProduct) return

      this.onUnitQuantityChange()
      this.calculateModifiers()
      this.isRequiredOptionsValid()
      this.calculateOptionsPrices()
    },

    onCheckBoxClick (value) {
      if ((value.Mandatory && value.Selected) || value.Disabled) return
      this.onCheckBoxChange()
    },
    onCheckBoxChange () {
      if (!this.selectedProduct) return

      this.isRequiredOptionsValid()
      this.calculateOptionsPrices()
    },

    onUnitQuantityChange () {
      if (!this.selectedProduct || this.selectedProduct.UnitId <= 1) return

      let optionsPrice = 0
      const product = this.selectedProduct
      const options = product?.Options || []

      for (let i = 0; i < options.length; i++) {
        const item = options[i]
        for (let ii = 0; ii < item.OptionValues.length; ii++) {
          const option = item.OptionValues[ii]
          if (item.DisplayType === 'unit') {
            if (item.DefaultValueId.toString() === option.Id.toString()) {
              product.UnitQuantity = parseInt(option.Price)
              option.Selected = true
            } else {
              option.Selected = false
            }
          }

          if (item.DisplayType === 'unit_value') {
            if (item.DefaultValueId.toString() === option.Id.toString()) {
              optionsPrice = optionsPrice + parseFloat(option.Price)
              option.Selected = true
            } else {
              option.Selected = false
            }
          }
        }
      }

      const measurementUnit = this.getMeasurementUnitById(product.UnitId)
      const divisor = parseFloat(measurementUnit?.Divisor ?? 1)
      const menuBasePrice = parseFloat(product.OriginalUnitPrice) + optionsPrice
      const price = parseFloat((menuBasePrice * divisor * parseInt(product.UnitQuantity)).toFixed(2))

      product.OriginalPrice = price
    },

    calculateModifiers () {
      const product = this.selectedProduct
      const options = product?.Options || []

      for (let i = 0; i < options.length; i++) {
        const item = options[i]
        for (let ii = 0; ii < item.OptionValues.length; ii++) {
          const value = item.OptionValues[ii]
          if (item.DisplayType === 'radio') {
            if (item.DefaultValueId.toString() === value.Id.toString()) {
              if (value.MultiValues) {
                this.setModifiers(value)
              }
            }
          }
        }
      }
      if (product.hasOwnProperty('ModifierRuns')) product.ModifierRuns++
    },

    setModifiers (value) {
      if (!value.MultiValues) return

      if (value.MultiValues.hasOwnProperty('optionsModifiers')) {
        for (const optionId in value.MultiValues.optionsModifiers) {
          const option = this.selectedMenuItem.Options.find(menuOption => menuOption.Id === optionId)
          const optionModifier = value.MultiValues.optionsModifiers[optionId]

          if (optionModifier.hasOwnProperty('MaxChoices')) option.MaxChoices = optionModifier.MaxChoices
          if (optionModifier.hasOwnProperty('MinChoices')) option.MinChoices = optionModifier.MinChoices
          if (optionModifier.hasOwnProperty('Required')) option.Required = optionModifier.Required
          if (optionModifier.hasOwnProperty('Priority')) option.Priority = optionModifier.Priority
          if (optionModifier.hasOwnProperty('Visible')) {
            option.Visible = optionModifier.Visible
          } else {
            option.Visible = true
          }
        }
      }

      if (value.MultiValues.hasOwnProperty('optionValuesModifiers')) {
        for (const optionValueId in value.MultiValues.optionValuesModifiers) {
          const option = this.selectedMenuItem.Options.find(menuOption => menuOption.OptionValues.some(value => value.Id === optionValueId))
          const optionValue = option.OptionValues.find(value => value.Id === optionValueId)
          const optionModifier = value.MultiValues.optionValuesModifiers[optionValueId]

          if (optionModifier.hasOwnProperty('Priority')) optionValue.Priority = optionModifier.Priority
          if (optionModifier.hasOwnProperty('Disabled')) optionValue.Disabled = optionModifier.Disabled
          if (optionModifier.hasOwnProperty('Visible')) {
            optionValue.Visible = optionModifier.Visible
          } else {
            optionValue.Visible = true
          }
          if (optionModifier.hasOwnProperty('ShowPrice')) optionValue.ShowPrice = optionModifier.ShowPrice
          if (optionModifier.hasOwnProperty('Price')) {
            optionValue.Price = parseFloat(optionModifier.Price)
            optionValue.PriceFmt = parseFloat(optionModifier.Price)
          }

          if (optionModifier.hasOwnProperty('Selected') && ((this.selectedMenuItem.ModifierRuns > 0 && this.selectedMenuItem.RowId) || !this.selectedMenuItem.RowId)) {
            optionValue.Selected = optionModifier.Selected
          }
        }
      }
    },

    isRequiredOptionsValid () {
      if (!this.selectedProduct) return false
      let isValid = 0
      const options = this.selectedProduct?.Options || []

      for (let i = 0; i < options.length; i++) {
        const item = options[i]
        let selectedOptionsCount = 0
        item.Error = false

        if (item.Required) {
          if (item.DisplayType === 'radio') {
            selectedOptionsCount = item.OptionValues.filter((option) => {
              return item.DefaultValueId.toString() === option.Id.toString()
            }).length
            if (selectedOptionsCount === 0) {
              item.Error = {
                Type      : 'MinChoices',
                Selected  : selectedOptionsCount,
                MinChoices: item.MinChoices,
                MaxChoices: item.MaxChoices,
                Message   : this.$tc('Cart.Message.Error.Product.Option.MinChoices.Message', item.MinChoices, { MinChoices: item.MinChoices })
              }
            }
          }
        }

        if (item.DisplayType === 'checkbox') {
          selectedOptionsCount = item.OptionValues.filter((option) => {
            return option.Selected
          }).length

          if (selectedOptionsCount < item.MinChoices || (item.Required && selectedOptionsCount === 0)) {
            item.Error = {
              Type      : 'MinChoices',
              Selected  : selectedOptionsCount,
              MinChoices: item.MinChoices,
              MaxChoices: item.MaxChoices,
              Message   : this.$tc('Cart.Message.Error.Product.Option.MinChoicesRequired.Message', item.MinChoices, { MinChoices: item.MinChoices })
            }
          }
          if (selectedOptionsCount > item.MaxChoices && item.MaxChoices > 0) {
            item.Error = {
              Type      : 'MaxChoices',
              Selected  : selectedOptionsCount,
              MinChoices: item.MinChoices,
              MaxChoices: item.MaxChoices,
              Message   : this.$tc('Cart.Message.Error.Product.Option.MaxChoices.Message', item.MaxChoices, { MaxChoices: item.MaxChoices })
            }
          }
          if ((selectedOptionsCount < item.MinChoices || selectedOptionsCount > item.MaxChoices) && (item.MaxChoices >= item.MinChoices && item.MaxChoices > 0)) {
            item.Error = {
              Type      : 'Between',
              Selected  : selectedOptionsCount,
              MinChoices: item.MinChoices,
              MaxChoices: item.MaxChoices,
              Message   : item.MinChoices === item.MaxChoices
                ? this.$tc('Cart.Message.Error.Product.Option.MinChoices.Message', item.MinChoices, { MinChoices: item.MinChoices })
                : (item.MinChoices === 0 && item.MaxChoices > 0)
                  ? this.$tc('Cart.Message.Error.Product.Option.MaxChoices.Message', item.MaxChoices, { MaxChoices: item.MaxChoices })
                  : this.$tc('Cart.Message.Error.Product.Option.Between.Message', item.MaxChoices, {
                    MinChoices: item.MinChoices,
                    MaxChoices: item.MaxChoices
                  })
            }
          }
        }

        if (item.Error) isValid++
      }
      return isValid === 0
    },

    calculateOptionsPrices () {
      let price = 0
      let priceSpecial = 0
      const product = this.selectedProduct
      let options = product?.Options || []

      if (product.OriginalPrice) {
        price = parseFloat(product.OriginalPrice)
      } else {
        product.OriginalPrice = product.Price
        price = parseFloat(product.Price)
      }

      if (product.OriginalSpecialPrice) {
        priceSpecial = parseFloat(product.OriginalSpecialPrice)
      } else {
        product.OriginalSpecialPrice = product.SpecialPrice
        priceSpecial = parseFloat(product.SpecialPrice)
      }

      if (!Array.isArray(options)) {
        options = Object.keys(options).map((k) => options[k])
      }

      for (let i = 0; i < options.length; i++) {
        const item = options[i]
        for (let ii = 0; ii < item.OptionValues.length; ii++) {
          const option = item.OptionValues[ii]
          if (item.DisplayType === 'checkbox') {
            if (option.Selected) {
              price = parseFloat(price) + parseFloat(option.Price)
              priceSpecial = parseFloat(priceSpecial) + parseFloat(option.Price)
            }
          } else if (item.DisplayType === 'radio') {
            if (item.DefaultValueId.toString() === option.Id.toString()) {
              option.Selected = true
              price = parseFloat(price) + parseFloat(option.Price)
              priceSpecial = parseFloat(priceSpecial) + parseFloat(option.Price)
            } else {
              option.Selected = false
            }
          }
        }
      }

      product.Price = (product.SpecialStatus && product.IsSpecial) ? priceSpecial : price
      product.PriceFmt = price
      product.SpecialPrice = priceSpecial
      product.SpecialPriceFmt = priceSpecial
    },

    getMeasurementUnitById (id) {
      if (!id) return null

      return this.$t('Common.Units')?.find(item => item.Id === id) || null
    }
  }

}
