export default {
  Button: {
    Login         : 'Σύνδεση',
    Logout        : 'Έξοδος',
    Register      : 'Εγγραφή',
    ForgotPassword: 'Ξέχασα τον κωδικό πρόσβασης',
    ResetPassword : 'Επαναφορά Κωδικού',
    NoAccount     : 'Δεν έχετε λογαριασμό; Εγγραφείτε με Email!',
    WithAccount   : 'Έχετε ήδη λογαριασμό; Κάντε Είσοδο!'
  },

  Login: {
    Success: 'Έχετε συνδεθεί με επιτυχία.'
  },

  Logout: {
    Success: 'Έχετε αποσυνδεθεί με επιτυχία.'
  },

  AccountDialog: {
    MemberSince: 'Μέλος από'
  },

  LoginDialog: {
    Title   : 'Σύνδεση',
    Subtitle: 'Κάντε είσοδο παρακάτω',
    Social  : {
      Title   : 'Κάντε είσοδο με τον social λογαριασμό σας',
      Google  : 'Είσοδος με Google',
      Facebook: 'Είσοδος με Facebook'
    },
    Account: {
      Title: 'ή κάντε είσοδο με το email σας',
      Field: {
        Email: {
          Label      : 'Διεύθυνση Email',
          Hint       : '',
          Placeholder: 'Διεύθυνση Email'
        },
        Password: {
          Label      : 'Κωδικός',
          Hint       : '',
          Placeholder: 'Κωδικός'
        }
      }
    }
  },

  ResetPasswordDialog: {
    Title   : 'Υπενθύμιση κωδικού',
    Subtitle: 'Θα σας σταλεί email με νέο κωδικό πρόσβασης.',
    Field   : {
      Email: {
        Label      : 'Email',
        Hint       : '',
        Placeholder: 'Email'
      }
    }
  },

  RegisterDialog: {
    Title   : 'Εγγραφή',
    Subtitle: 'Εγγραφή με Email',
    Field   : {
      Firstname: {
        Label      : 'Όνομα',
        Hint       : '',
        Placeholder: 'Όνομα'
      },
      Lastname: {
        Label      : 'Επώνυμο',
        Hint       : '',
        Placeholder: 'Επώνυμο'
      },
      Email: {
        Label      : 'Διεύθυνση Email',
        Hint       : '',
        Placeholder: 'Διεύθυνση Email'
      },
      Phone: {
        Label      : 'Κινητό Τηλέφωνο',
        Hint       : '',
        Placeholder: 'Κινητό Τηλέφωνο'
      },
      Password: {
        Label      : 'Κωδικός',
        Hint       : '',
        Placeholder: 'Κωδικός'
      },
      PasswordConfirm: {
        Label      : 'Επιβεβαίωση Κωδικού',
        Hint       : '',
        Placeholder: 'Επιβεβαίωση Κωδικού'
      },
      TermsCondition: {
        Label: 'Συμφωνώ με τους',
        Link : 'όρους και προϋποθέσεις'
      }
    }
  }
}
