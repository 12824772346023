export default {
  name: 'productCommon',
  data () {
    return {}
  },
  computed: {
    productMaxQuantity () {
      return this.selectedProduct.SubtractStock ? this.selectedProduct.StockQuantity : undefined
    },
    productMinQuantity () {
      return this.selectedProduct.UnitId > 1 && !this.productHasUnitQuantityOptions ? this.selectedProduct.DefaultUnitQuantity || 1000 : this.selectedProduct.MinimumQuantity || 1
    },
    productQuantityStep () {
      return this.selectedProduct.UnitId > 1 && !this.productHasUnitQuantityOptions ? this.selectedProduct.DefaultUnitQuantity || 1000 : 1
    },

    productQuantity: {
      get: function () {
        return this.selectedProduct.UnitId > 1 && !this.productHasUnitQuantityOptions ? this.unitQuantity : this.quantity
      },
      set: function (val) {
        if (this.selectedProduct.UnitId > 1 && !this.productHasUnitQuantityOptions) {
          this.unitQuantity = parseInt(val)
        } else {
          this.quantity = parseInt(val)
        }
      }
    },

    quantity: {
      get: function () {
        return this.selectedProduct?.Quantity || 1
      },
      set: function (val) {
        this.selectedProduct.Quantity = parseInt(val)
      }
    },

    unitQuantity: {
      get: function () {
        return this.selectedProduct?.UnitQuantity || 1000
      },
      set: function (val) {
        this.selectedProduct.UnitQuantity = parseInt(val)
      }
    },

    productHasUnitQuantityOptions () {
      const options = this.selectedProduct?.Options || []
      let retVal = false

      for (let i = 0; i < options.length; i++) {
        const item = options[i]
        for (let ii = 0; ii < item.OptionValues.length; ii++) {
          if (item.DisplayType === 'unit') {
            retVal = true
          }
        }
      }

      return retVal
    }
  },
  methods: {}

}
