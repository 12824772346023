<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="500"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar>
            <v-icon
              color="brand-primary"
              x-large
            >
              mdi-email-edit
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Auth.RegisterDialog.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Auth.RegisterDialog.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            class="grey lighten-3 ma-0"
            color="black"
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text
          ref="formContainer"
          class="pa-lg-4 pa-md-2 px-1 py-0"
        >
          <v-form
            v-if="model"
            ref="form"
            @submit.prevent
          >
            <v-container fluid>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    v-model="model.Firstname"
                    :hint="$t('Auth.RegisterDialog.Field.Firstname.Hint')"
                    :label="$t('Auth.RegisterDialog.Field.Firstname.Label')"
                    :placeholder="$t('Auth.RegisterDialog.Field.Firstname.Placeholder')"
                    :rules="model.validator.vuetifyFormFieldRules('Firstname')"
                    clearable
                    name="Firstname"
                    outlined
                    prepend-inner-icon="mdi-account-circle"
                    type="text"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="model.Lastname"
                    :hint="$t('Auth.RegisterDialog.Field.Lastname.Hint')"
                    :label="$t('Auth.RegisterDialog.Field.Lastname.Label')"
                    :placeholder="$t('Auth.RegisterDialog.Field.Lastname.Placeholder')"
                    :rules="model.validator.vuetifyFormFieldRules('Lastname')"
                    clearable
                    name="Lastname"
                    outlined
                    prepend-inner-icon="mdi-account-circle"
                    type="text"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="model.Email"
                    :hint="$t('Auth.RegisterDialog.Field.Email.Hint')"
                    :label="$t('Auth.RegisterDialog.Field.Email.Label')"
                    :placeholder="$t('Auth.RegisterDialog.Field.Email.Placeholder')"
                    :rules="model.validator.vuetifyFormFieldRules('Email')"
                    clearable
                    name="Email"
                    outlined
                    prepend-inner-icon="mdi-email"
                    type="text"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="model.Phone"
                    :hint="$t('Auth.RegisterDialog.Field.Phone.Hint')"
                    :label="$t('Auth.RegisterDialog.Field.Phone.Label')"
                    :placeholder="$t('Auth.RegisterDialog.Field.Phone.Placeholder')"
                    :rules="model.validator.vuetifyFormFieldRules('Phone')"
                    clearable
                    name="mobile-phone"
                    outlined
                    prepend-inner-icon="mdi-cellphone-text"
                    type="tel"
                  />
                </v-col>

                <template v-if="!item">
                  <v-col cols="12">
                    <v-text-field
                      v-model="model.Password"
                      :hint="$t('Auth.RegisterDialog.Field.Password.Hint')"
                      :label="$t('Auth.RegisterDialog.Field.Password.Label')"
                      :placeholder="$t('Auth.RegisterDialog.Field.Password.Placeholder')"
                      :rules="model.validator.vuetifyFormFieldRules('Password')"
                      clearable
                      name="Password"
                      outlined
                      prepend-inner-icon="mdi-lock"
                      type="password"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="model.PasswordConfirm"
                      :hint="$t('Auth.RegisterDialog.Field.PasswordConfirm.Hint')"
                      :label="$t('Auth.RegisterDialog.Field.PasswordConfirm.Label')"
                      :placeholder="$t('Auth.RegisterDialog.Field.PasswordConfirm.Placeholder')"
                      :rules="model.validator.vuetifyFormFieldRules('PasswordConfirm')"
                      clearable
                      name="PasswordConfirm"
                      outlined
                      prepend-inner-icon="mdi-lock"
                      type="password"
                    />
                  </v-col>
                </template>

                <v-col cols="12">
                  <v-checkbox
                    v-model="model.TermsCondition"
                    :rules="model.validator.vuetifyFormFieldRules('TermsCondition')"
                    :value="true"
                    class="mt-0"
                    name="TermsCondition"
                    required
                  >
                    <template #label>
                      <div>
                        {{ $t('Auth.RegisterDialog.Field.TermsCondition.Label') }}
                        <a
                          :href="`/${$i18n.locale}/page/policies/terms`"
                          target="_blank"
                          @click.stop
                        >
                          {{
                            $t('Auth.RegisterDialog.Field.TermsCondition.Link')
                          }}
                        </a>
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>

                <v-col
                  v-if="showGenericError && genericError"
                  class="py-0"
                  cols="12"
                >
                  <v-alert
                    border="left"
                    dense
                    text
                    type="error"
                  >
                    {{ genericError }}
                  </v-alert>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-btn
                    :disabled="isFormSaving"
                    :loading="isFormSaving"
                    block
                    class="text-capitalize white--text"
                    color="brand-primary"
                    depressed
                    x-large
                    @click="onRegisterClick"
                  >
                    {{ $t('Auth.Button.Register') }}
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    block
                    class="text-none mt-3"
                    plain
                    small
                    @click="onLoginClick"
                  >
                    {{ $t('Auth.Button.WithAccount') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import DialogVisible from '@/mixins/dialog/dialogVisible'
import RegisterModel from '@/api/models/user/RegisterModel'
import DialogData from '@/mixins/dialog/dialogData'
import API from '@/api/Api'

export default {
  name      : 'RegisterDialog',
  components: {},
  mixins    : [DialogVisible, DialogData],
  props     : {},
  dataModel : RegisterModel,
  data () {
    return {}
  },
  computed: {},
  methods : {
    onRegisterClick () {
      this.saveFromData()
    },

    saveFromData () {
      // this.$refs.form.validate()
      if (!this.validateForm() || this.isFormSaving) return
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      this.isFormSaving = true

      this.model.Register()
        .then(response => {
          this.handleFormSaveResponse(response)
        })
        .catch(error => {
          this.handleFormSaveResponse(error.response)
        })
        .finally(() => {
          this.isFormSaving = false
        })
    },

    handleFormSaveResponse (response) {
      const data = API.responseData(response) || {}
      const message = data?.Messages

      if (API.isResponseSuccess(response)) {
        if (message.Success) {
          this.formSaveSuccess(data)
          this.autoLogin(response, data)

          this.$bus.$emit('app:show-notification', {
            body   : message.Success,
            type   : 'success',
            icon   : 'mdi-check-circle',
            timeout: 5000
          })
        } else {
          this.formSaveError(message?.Error || message?.Warning || message?.Info, true)
        }
      } else {
        this.formSaveError(message?.Error || message?.Warning || message?.Info, true)
      }
    },

    onLoginClick () {
      this.onCancelButtonClick()
      this.$bus.$emit('login:show-dialog')
    },

    onCancelButtonClick () {
      this.isVisible = false
    }
  }
}
</script>

<style scoped>

</style>
