<template>
  <v-row>
    <v-col cols="12">
      <div class="d-flex">
        <div class="d-inline-flex my-auto">
          <h2
            :class="titleClass"
            style="line-height: initial;"
          >
            {{ title }}
          </h2>
        </div>

        <v-spacer />

        <div class="d-inline-flex my-auto">
          <v-btn
            :disabled="!hasPrev"
            class="mx-1 brand-primary lighten-5"
            color="brand-primary"
            icon
            @click="onAffixClick('prev')"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-btn
            :disabled="!hasNext"
            class="mx-1 brand-primary lighten-5"
            color="brand-primary"
            icon
            @click="onAffixClick('next')"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>
    </v-col>

    <v-col cols="12">
      <v-slide-group
        ref="categoriesSlideGroup"
        class="categories-slider-group"
        show-arrows="always"
      >
        <v-slide-item
          v-for="(store, idx) in storesFiltered"
          :key="`category-${idx}`"
        >
          <div
            :style="`width: ${cardWidth}px;`"
            class="ml-1 mr-5 mb-1"
          >
            <brand-card
              :dense="dense"
              :item="store"
              hover
              @click="onItemClick(store)"
            />
          </div>
        </v-slide-item>
      </v-slide-group>
    </v-col>
  </v-row>
</template>

<script>

import BrandCard from '@/theme/default/views/home/BrandCard'
import StoreCommon from '@/mixins/store/storeCommon'

export default {
  name      : 'BrandSlider',
  components: {
    BrandCard
  },
  directives: {},
  mixins    : [StoreCommon],
  props     : {
    title: {
      type   : String,
      default: ''
    },

    titleClass: {
      type   : String,
      default: 'text-h6'
    },

    stores: {
      type   : Array,
      default: () => []
    },

    dense: {
      type   : Boolean,
      default: false
    }
  },
  enums: {},
  data () {
    return {
      hasPrev: false,
      hasNext: false
    }
  },
  computed: {
    storesFiltered () {
      return this.stores?.filter(store => !this.storeIsClosed(store)) || []
    },

    cardWidth () {
      if (this.$vuetify.breakpoint.xs) return this.dense ? 120 : 120
      if (this.$vuetify.breakpoint.sm) return this.dense ? 120 : 120
      if (this.$vuetify.breakpoint.md) return this.dense ? 120 : 150
      if (this.$vuetify.breakpoint.lg) return this.dense ? 120 : 180

      return this.dense ? 110 : 230
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
    this.$watch(() => this.$refs.categoriesSlideGroup.hasPrev, (newValue) => {
      this.hasPrev = newValue
    })
    this.$watch(() => this.$refs.categoriesSlideGroup.hasNext, (newValue) => {
      this.hasNext = newValue
    })
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    /**
     * onAffixClick
     *
     * @param location {String}
     */
    onAffixClick (location) {
      this.$refs.categoriesSlideGroup.onAffixClick(location)
    },

    onItemClick (store) {
      if (this.$route.name === 'Store') {
        this.$bus.$emit('store:selected', store)
      } else {
        this.$router.push({
          name  : 'Store',
          params: {
            slug: store.Slug,
            id  : store.Id
          }
        })
      }
    }
  }
}
</script>

<style scoped>
/deep/ .categories-slider-group .v-slide-group__prev, /deep/ .categories-slider-group .v-slide-group__next {
  display: none;
}

/deep/ .theme--dark.v-btn.v-btn--disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

/deep/ .theme--dark.v-btn.v-btn--disabled .v-icon {
  color: rgba(0, 0, 0, 0.26) !important;
}
</style>
