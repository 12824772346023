<template>
  <div
    v-if="product"
    :id="`product-${product.Id}-quantity-selector-${product.RowId}`"
    class="d-inline-flex"
    @click.stop="() => {}"
  >
    <v-menu
      v-model="menu"
      :attach="`#product-${product.Id}-quantity-selector-${product.RowId}`"
      :close-on-content-click="false"
      left
      max-height="45"
      origin="right center"
      transition="slide-x-reverse-transition"
    >
      <template #activator="{on, attrs}">
        <v-btn
          class="pa-1"
          color="brand-primary"
          outlined
          style="min-width: 50px; height: 32px;"
          v-bind="attrs"
          v-on="on"
        >
          {{ vModel }}

          <v-icon small>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>

      <div
        class="brand-primary lighten-5 rounded d-inline-flex justify-center align-center pa-2"
        style="height: 45px;"
      >
        <v-btn
          class="white mr-4"
          color="brand-primary"
          icon
          small
          @click.stop="onRemoveClick"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>

        <v-btn
          class="white"
          color="brand-primary"
          icon
          small
          @click.stop="onDecrementClick"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>

        <div
          ref="quantity"
          class="px-2 brand-primary--text text-center text-no-select"
          style="min-width: 44px;"
        >
          <span>{{ quantity }}</span>
        </div>

        <v-btn
          :disabled="vModel === parseInt(max)"
          class="white"
          color="brand-primary"
          icon
          small
          @click.stop="onIncrementClick"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-menu>
  </div>
</template>

<script>

import VModel from '@/mixins/vModel'
import { formatUnitQuantity } from '@/lib/utils/currency/currency'

export default {
  name      : 'ProductCartQuantitySelector',
  components: {},
  directives: {},
  mixins    : [VModel],
  props     : {
    product: {
      type   : Object,
      default: undefined
    },

    min: {
      type   : [Number, String],
      default: 1
    },

    max: {
      type   : [Number, String],
      default: undefined
    },

    step: {
      type   : [Number, String],
      default: 1
    },

    unitId: {
      type   : Number,
      default: 1
    },

    quantityAnimation: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  data () {
    return {
      menu           : null,
      debounceTimeout: null
    }
  },
  computed: {
    quantity () {
      return this.unitId > 1 ? formatUnitQuantity(this.$i18n.locale, this.vModel, this.getMeasurementUnitById(this.unitId)) : this.vModel
    }
  },
  watch: {
    'product.LoadingUpdate': function (newVal, oldVal) {
      if (oldVal && !newVal) this.menu = false
    },

    min: {
      immediate: true,
      handler (newVal) {
        if (newVal > this.vModel) this.vModel = newVal
      }
    }
  },
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    formatUnitQuantity,

    onRemoveClick () {
      if (!this.product) return

      this.$bus.$emit('cart:remove-product', this.product)
      this.menu = false
    },

    onDecrementClick () {
      if (!this.product) return

      if (this.vModel <= parseInt(this.min)) {
        this.onRemoveClick()
        return
      }

      if (this.vModel > parseInt(this.min)) this.vModel -= parseInt(this.step || 1)
      this.triggerQuantityAnimation()
      this.handleUpdateQuantity()
    },

    onIncrementClick () {
      if (!this.product) return

      if (!this.max || this.vModel < parseInt(this.max)) this.vModel += parseInt(this.step || 1)
      this.triggerQuantityAnimation()
      this.handleUpdateQuantity()
    },

    updateQuantity () {
      if (!this.product) return

      this.$bus.$emit('cart:update-product', this.product)
    },

    handleUpdateQuantity () {
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => {
        this.updateQuantity()
      }, 500)
    },

    getMeasurementUnitById (id) {
      if (!id) return null

      return this.$t('Common.Units')?.find(item => item.Id === id) || null
    },

    triggerQuantityAnimation () {
      if (!this.quantityAnimation) return

      const el = this.$refs.quantity
      if (!el) return

      el.style.animation = 'none'
      // eslint-disable-next-line no-unused-expressions
      el.offsetHeight /* trigger reflow */
      el.style.animation = null
      el.classList.add('quantityTextAnimation')
    }
  }
}
</script>

<style scoped>
@keyframes quantityTextAnimationFrames {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.quantityTextAnimation {
  animation: quantityTextAnimationFrames 250ms ease;
}
</style>
