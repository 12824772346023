<template>
  <v-navigation-drawer
    v-model="navigationDrawerVisible"
    app
    right
    style="max-width: 450px; min-height: 100vh !important; height: -webkit-fill-available !important; min-height: -webkit-fill-available !important;"
    temporary
    width="95%"
  >
    <template #prepend>
      <v-toolbar
        flat
        height="74"
      >
        <v-badge
          class="mt-1 mr-4"
          color="brand-primary"
          overlap
        >
          <template #badge>
            <span class="white--text">{{ cartProductsCount }}</span>
          </template>

          <v-icon
            color="brand-primary-"
            size="26"
          >
            mdi-cart
          </v-icon>
        </v-badge>

        <v-toolbar-title class="title font-weight-bold">
          {{ $t('Cart.Title') }}
        </v-toolbar-title>

        <v-spacer />

        <v-btn
          class="grey lighten-3 ma-0"
          color="black"
          icon
          @click="toggleNavigationDrawer"
        >
          <v-icon large>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider />
    </template>

    <template v-if="cartProductsCount">
      <template v-if="AppType.isQRScanApp && AppQrData">
        <qr-code-order-data class="" />
        <v-divider />
      </template>

      <template v-if="AppConfig.Cart.CouponsEnabled">
        <v-container
          fluid
          px-4
          py-0
        >
          <v-row dense>
            <v-col
              v-if="!cartCoupon"
              cols="12"
            >
              <v-expansion-panels
                accordion
                flat
              >
                <v-expansion-panel class="transparent">
                  <v-expansion-panel-header class="px-0 py-1 title brand-primary--text">
                    <div>
                      <v-icon
                        class="mr-2"
                        color="brand-primary"
                      >
                        mdi-tag
                      </v-icon>

                      {{ $t('Cart.Coupon.Title') }}
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content class="cart-coupon">
                    <v-sheet
                      :class="[{'grey lighten-5': !$vuetify.theme.dark}]"
                      class="pa-4 rounded"
                    >
                      <v-text-field
                        v-model="couponValue"
                        :label="$t('Cart.Coupon.Label')"
                        clearable
                        color="brand-primary"
                        hide-details="auto"
                        outlined
                      />

                      <v-btn
                        :disabled="couponLoading"
                        :large="$vuetify.breakpoint.xsOnly"
                        :loading="couponLoading"
                        :x-large="$vuetify.breakpoint.smAndUp"
                        block
                        class="text-capitalize mt-2"
                        color="brand-primary lighten-5 brand-primary--text"
                        depressed
                        @click="cartAddCoupon(couponValue)"
                      >
                        {{ $t('Cart.Coupon.Button.Apply') }}
                      </v-btn>
                    </v-sheet>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>

            <v-col
              v-else
              cols="12"
            >
              <v-list class="pa-0">
                <v-list-item
                  class="px-0"
                  style="height: 72px;"
                >
                  <div class="left mr-3">
                    <v-icon color="brand-primary">
                      mdi-tag
                    </v-icon>
                  </div>

                  <v-list-item-content>
                    <v-list-item-title class="subheading font-weight-medium">
                      {{ $t('Cart.Coupon.Item') }}
                    </v-list-item-title>

                    <v-list-item-subtitle class="">
                      {{ cartCoupon.code }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text class="body-2">
                      -{{ cartCoupon.amount | currency }}
                    </v-list-item-action-text>

                    <v-btn
                      :disabled="couponLoading"
                      :loading="couponLoading"
                      class="pa-0"
                      color="grey lighten-1"
                      plain
                      style="min-width: 36px;"
                      @click.stop="cartRemoveCoupon(cartCoupon.code)"
                    >
                      <v-icon color="brand-primary">
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>

        <v-divider />
      </template>

      <v-list three-line>
        <template v-if="AppConfig.Cart.GroupByStore">
          <template v-for="(store, storeIdx) in cartProductsGroupedByStore">
            <div
              :key="`cart-store-${storeIdx}`"
              class="px-4 py-2 d-flex align-center fill-width"
            >
              <v-avatar
                v-if="AppConfig.Cart.GroupShowStoreLogo"
                class="mr-2"
                size="32"
              >
                <img :src="store.Logo">
              </v-avatar>

              <div class="title">
                {{ store.Name }}
              </div>

              <v-spacer />

              <div class="grey--text text-caption text-no-wrap pl-2">
                <v-chip
                  v-if="storeIsOpened(store)"
                  color="brand-primary"
                  dark
                  label
                  small
                >
                  {{ storeDefaultTime(store) }}
                </v-chip>

                <v-chip
                  v-else
                  color="red"
                  dark
                  label
                  small
                >
                  {{ $t('Store.Status.Closed').toLocaleUpperCase($i18n.locale) }}
                </v-chip>
              </div>
            </div>

            <template v-for="(product, productIdx) in store.Items">
              <v-lazy
                :key="`cart-store-${storeIdx}-product-lazy-${productIdx}`"
                min-height="88"
                transition="fade-transition"
              >
                <cart-product-list-item
                  :key="`cart-store-${storeIdx}-product-${productIdx}`"
                  :product="product"
                  :store-logo="AppConfig.Cart.ProductShowStoreLogo"
                />
              </v-lazy>

              <v-divider
                :key="`cart-store-${storeIdx}-product-divider-${productIdx}`"
                class="mx-4"
              />
            </template>
          </template>
        </template>

        <template v-else>
          <template v-for="(product, idx) in AppCart.Items">
            <v-lazy
              :key="`cart-product-lazy-${idx}`"
              min-height="88"
              transition="fade-transition"
            >
              <cart-product-list-item
                :key="`cart-product-${idx}`"
                :product="product"
                :product-index="idx"
              />
            </v-lazy>

            <v-divider
              :key="`cart-product-divider-${idx}`"
              class="mx-4"
            />
          </template>
        </template>
      </v-list>

      <v-container
        v-if="recommendedStores.length"
        fluid
      >
        <!--        <recommendation-slider
                  :recommendations="recommendations"
                  title="Recommendations"
                />-->

        <brand-slider
          :stores="recommendedStores"
          :title="$t('Cart.BrandSlider.Title')"
          dense
        />
      </v-container>
    </template>

    <template v-else>
      <v-container
        class="text-no-select"
        fill-height
        fluid
      >
        <v-row>
          <v-col cols="12">
            <div class="text-center">
              <div class="mb-6">
                <v-icon
                  color="brand-primary"
                  size="256"
                >
                  mdi-basket-plus
                </v-icon>
              </div>

              <h1>{{ $t('Cart.Message.Info.NoItems.Title') }}</h1>

              <h4 class="font-weight-regular mt-4">
                {{ $t('Cart.Message.Info.NoItems.Subtitle') }}
              </h4>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #append>
      <v-divider />

      <v-container fluid>
        <v-row>
          <v-col
            v-if="cartProductsCount"
            cols="12"
          >
            <v-btn
              v-if="orderTotalAmountValid"
              :disabled="!orderTotalAmountValid"
              :large="$vuetify.breakpoint.xsOnly"
              :x-large="$vuetify.breakpoint.smAndUp"
              block
              class="text-capitalize white--text pl-2 pr-3"
              color="brand-primary"
              depressed
              @click="onCheckoutDetails"
            >
              <v-badge
                v-if="cartProductsCount"
                class="mr-2"
                color="white"
                inline
              >
                <template #badge>
                  <span class="brand-primary--text">{{ cartProductsCount }}</span>
                </template>
              </v-badge>

              {{ $t('Cart.Button.Checkout') }}

              <v-spacer />

              <span class="ml-4">{{ orderTotal | currency }}</span>
            </v-btn>

            <payment-minimum-order-warning
              v-else
              :dense="$vuetify.breakpoint.xsOnly"
              sort-text
            />
          </v-col>

          <v-col
            v-else
            cols="12"
          >
            <v-btn
              :large="$vuetify.breakpoint.xsOnly"
              :x-large="$vuetify.breakpoint.smAndUp"
              block
              class="text-capitalize pl-2 pr-3"
              color="brand-primary lighten-5 brand-primary--text"
              dark
              depressed
              @click="navigationDrawerVisible = false"
            >
              {{ $t('Cart.Button.AddItems') }}
            </v-btn>
          </v-col>
        </v-row>

        <product-details-dialog
          :edit-mode="productDetailsDialogEditMode"
          :product="selectedProduct"
          :visible.sync="productDetailsDialogVisible"
        />
      </v-container>
    </template>
  </v-navigation-drawer>
</template>

<script>

import CartProductListItem from '@/theme/default/views/cart/CartProductListItem.vue'
import ProductDetailsDialog from '@/theme/default/components/product/ProductDetailsDialog.vue'
import ProductCommon from '@/mixins/product/productCommon'
import { clone } from '@/lib/utils/helper'
import { floatNumber } from '@/lib/helpers'
import CartCommon from '@/mixins/cart/cartCommon'
import CartActions from '@/mixins/cart/cartActions'
import PaymentMinimumOrderWarning from '@/theme/default/components/payment/common/PaymentMinimumOrderWarning.vue'
import BrandSlider from '@/theme/default/views/home/BrandSlider.vue'
import { uniqueElements } from '@/lib/utils/array'
import QrCodeOrderData from '@/theme/default/components/common/QrCodeOrderData.vue'

export default {
  name      : 'CartDrawer',
  components: {
    QrCodeOrderData,
    BrandSlider,
    PaymentMinimumOrderWarning,
    ProductDetailsDialog,
    CartProductListItem
  },
  directives: {},
  mixins    : [CartCommon, CartActions, ProductCommon],
  props     : {
    navigationDrawer: {
      type    : Boolean,
      required: true
    },
    items: {
      type    : Array,
      required: true
    }
  },
  dataStore: {
    AppCart  : 'App.Cart',
    AppStores: 'App.Stores',
    AppType  : 'App.Type',
    StoreMenu: 'Store.Menu',
    StoreInfo: 'Store.Info'
  },
  data () {
    return {
      groupProductsByStore       : true,
      productDetailsDialogVisible: false,
      recommendations            : [
        {
          Name   : 'French Fries',
          Desc   : '',
          Price  : '2.95',
          Popular: true,
          Image  : 'https://imageproxy.wolt.com/menu/menu-images/5e873bc06b6e018ce080361e/0ad77b8e-dda2-11ec-ab26-0ec0b1d1d3d7_potatoes_1440x810_potatoes_145g.jpeg?w=960'
        },
        {
          Name   : 'Classic Burger',
          Desc   : '',
          Price  : '3.95',
          Popular: true,
          Image  : 'https://imageproxy.wolt.com/menu/menu-images/5e873bc06b6e018ce080361e/043cf986-dda0-11ec-bf0a-fe6999972ec4_burgers_1440x810_classic_burger.jpeg?w=960'
        },
        {
          Name   : 'Hot Wings',
          Desc   : '',
          Price  : '4.00',
          Popular: true,
          Image  : 'https://imageproxy.wolt.com/menu/menu-images/5e873bc06b6e018ce080361e/e1306a3e-dda1-11ec-9366-5e9e2d0eba54_chicken_1440x810_hot_wings.jpeg?w=960'
        },
        {
          Name   : 'Original Twister',
          Desc   : '',
          Price  : '3.95',
          Popular: false,
          Image  : 'https://imageproxy.wolt.com/menu/menu-images/5e873bc06b6e018ce080361e/c02dc7cc-dda2-11ec-a758-ba4d536ff6fe_wraps_1440x810_twister_original_menu.jpeg?w=960'
        },
        {
          Name   : 'Coleslaw',
          Desc   : '',
          Price  : '3.00',
          Popular: false,
          Image  : 'https://imageproxy.wolt.com/menu/menu-images/5e873bc06b6e018ce080361e/d89cc5da-dda0-11ec-98a3-7adadc89de1f_salads_1440x810_coleslaw.jpeg?w=960'
        },
        {
          Name   : 'Onion Rings',
          Desc   : '',
          Price  : '2.95',
          Popular: false,
          Image  : 'https://imageproxy.wolt.com/menu/menu-images/5e873bc06b6e018ce080361e/16cd7cd6-dda2-11ec-b0b4-9a327c03ce24_onions_1440x810_background.jpeg?w=960'
        }
      ]
    }
  },
  computed: {
    recommendedStores () {
      const storesInCart = uniqueElements(this.cartProducts?.map(item => item.StoreId) || [])
      const stores = this.AppStores || []

      return stores.filter(store => !storesInCart.includes(store.Id) && !this.storeIsClosed(store))
    },

    navigationDrawerVisible: {
      get () {
        return this.navigationDrawer
      },
      set (val) {
        this.$emit('update:navigationDrawer', val)
      }
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
    this.$bus.$on('product:details-dialog', this.showProductDetailsDialog)
    this.$bus.$on('cart:add-product', this.addProductToCart)
    this.$bus.$on('cart:quick-add-product', this.quickAddProductToCart)
    this.$bus.$on('cart:remove-product', this.removeProductFromCart)
    this.$bus.$on('cart:update-product', this.updateProductQuantityFromCart)
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off('product:details-dialog', this.showProductDetailsDialog)
    this.$bus.$off('cart:add-product', this.addProductToCart)
    this.$bus.$off('cart:quick-add-product', this.quickAddProductToCart)
    this.$bus.$off('cart:remove-product', this.removeProductFromCart)
    this.$bus.$off('cart:update-product', this.updateProductQuantityFromCart)
  },
  destroyed () {
  },
  methods: {
    floatNumber,

    quickAddProductToCart (product) {
      if (!product) return

      this.selectedProduct = product?.DetailsData ? clone(product.DetailsData) : clone(product)

      this.initProduct()
      if (!this.isRequiredOptionsValid()) {
        this.showProductDetailsDialog(clone(product))
        return
      }

      this.addProductToCart(this.selectedProduct)
    },

    addProductToCart (product) {
      if (!product) return
      this.cartAddProduct(product)
    },

    removeProductFromCart (product) {
      if (!product) return
      this.cartRemoveProduct(product)
    },

    updateProductQuantityFromCart (product) {
      if (!product) return
      this.cartUpdateProduct(product)
    },

    showProductDetailsDialog (product, editMode = false) {
      if (!product) return

      if (!editMode && product?.DetailsData) {
        const productItem = clone(product.DetailsData)
        productItem.ModifierRuns = 0
        this.selectedProduct = productItem
        this.productDetailsDialogEditMode = false
        this.productDetailsDialogVisible = true
      } else {
        this.productDetailsDialogEditMode = true
        this.cartProductOptions(clone(product))
      }
    },

    onCheckoutDetails () {
      this.$router.push({ name: 'CheckoutDetails' })
      this.navigationDrawerVisible = false
    },

    toggleNavigationDrawer () {
      this.navigationDrawerVisible = !this.navigationDrawerVisible
    }
  }
}
</script>

<style scoped>
/deep/ .cart-coupon .v-expansion-panel-content__wrap {
  padding: 0 0 16px 0;
}
</style>
