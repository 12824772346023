import Vue from 'vue'
import Storage from 'vue-web-storage'
import PortalVue from 'portal-vue'
import VueMeta from 'vue-meta'
import VueMask from 'v-mask'
import App from '@/App'
import VueFilters from '@/filters/index'
import vuetify from '@/plugins/vuetify'
import { resetRouter, router } from '@/router'
import { i18n, initI18nLanguages, setI18nLanguage } from '@/lang/lang'
import DataStorePlugin from '@/lib/data/dataStore/plugin/DataStorePlugin'
import UtilsPlugin from '@/lib/utils/plugin'
import EventBus from '@/lib/events/eventBus'
import Enums from '@/lib/enums/plugin'
import { themeUtils } from '@/lib/utils'
import DayjsPlugin from '@/lib/date/plugin'
import EnvMixin from '@/mixins/env'
import AuthMixin from '@/mixins/auth'
import { setupBugsnag } from '@/lib/services/Bugsnag'
import DataStore from '@/lib/data/dataStore/DataStore'
import API from '@/api/Api'
import { sortCompareAlphabetically } from '@/lib/utils/array'
import PromiseQueue from '@/lib/async/PromiseQueue'
import AppConfig from '@/mixins/appConfig'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import Snotify from '@/theme/default/components/common/vue-snotify/index'
import '@/theme/default/components/common/vue-snotify/styles/material.css'
import 'swiper/css/swiper.css'
import '@mdi/font/css/materialdesignicons.css'
import './App.css'
import axios from 'axios'
import UniversalSocialauth from 'universal-social-auth'
import RouterReadyMiddleware from '@/router/middleware/RouterReadyMiddleware'
import RouteMiddleware from '@/lib/middleware/RouteMiddleware'
import LangMiddleware from '@/router/middleware/LangMiddleware'
import AppTypeMiddleware from '@/router/middleware/AppTypeMiddleware'
import AuthMiddleware from '@/router/middleware/AuthMiddleware'
import StoreRouteMiddleware from '@/router/middleware/StoreRouteMiddleware'
import { mergeDeep } from '@/lib/utils/object'

require('@openfonts/roboto_greek')

const DefaultLayout = () => themeUtils.importThemeComponent('layouts/DefaultLayout')
const SimpleLayout = () => themeUtils.importThemeComponent('layouts/SimpleLayout')
// const DefaultLayout = import(/* webpackChunkName: "DefaultLayout" */ '@/theme/default/layouts/DefaultLayout')

Vue.use(Storage, {
  prefix : `${process.env.VUE_APP_STORAGE_PREFIX}_`,
  drivers: ['session', 'local']
})

const getSystemConfig = () => {
  API.Resource.Config.Get()
    .then(response => {
      API.isResponseSuccess(response) && init(API.responseData(response))
    })
    .catch(e => {
    })
    .finally(() => {
    })
}

const initVue = () => {
  resetRouter()

  Vue.component('DefaultLayout', DefaultLayout)
  Vue.component('SimpleLayout', SimpleLayout)

  Vue.mixin(EnvMixin)
  Vue.mixin(AuthMixin)
  Vue.mixin(AppConfig)

  Vue.use(VueFilters)
  Vue.use(EventBus)
  Vue.use(DataStorePlugin)
  Vue.use(UtilsPlugin)
  Vue.use(DayjsPlugin)
  Vue.use(Enums)
  Vue.use(PortalVue)
  Vue.use(VueMask)
  Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
  })

  Vue.use(VueAwesomeSwiper /* { default options with global component } */)

  Vue.use(Snotify, {
    config: {
      timeout        : 3500,
      showProgressBar: true,
      pauseOnHover   : true,
      titleMaxLength : 28,
      bodyMaxLength  : 150,
      backdrop       : -1
    },
    options: {
      maxOnScreen  : 3,
      maxAtPosition: 8,
      maxHeight    : 300,
      newOnTop     : true,
      position     : 'centerTop'
    }
  })

  const options = {
    providers: {
      ...DataStore.App.Config.Auth.Google && { google: DataStore.App.Config.Auth.Providers.google },
      ...DataStore.App.Config.Auth.Facebook && { facebook: DataStore.App.Config.Auth.Providers.facebook }
    }
  }
  const Oauth = new UniversalSocialauth(axios, options)
  Vue.prototype.$axios = axios
  Vue.prototype.$Oauth = Oauth

  window.Vue = new Vue({
    el        : '#app',
    directives: {},
    mixins    : [],
    data () {
      return {}
    },
    beforeMount () {
      setI18nLanguage()

      this.$router.onReady(RouterReadyMiddleware)
      this.$router.onReady(AppTypeMiddleware)

      router.beforeResolve(RouteMiddleware({
        LangMiddleware,
        AuthMiddleware
      }, true, this))

      router.afterEach(RouteMiddleware({
        StoreRouteMiddleware
      }, true, this))
    },
    beforeCreate () {
    },
    created () {
    },
    mounted () {
      this.$bus.$on('app:logout', this.logout)
    },
    beforeDestroy () {
      this.$bus.$off('app:logout', this.logout)
    },
    methods: {},
    i18n,
    router,
    vuetify,
    render : h => h(App)
  })

  if (process.env.NODE_ENV === 'production') {
    Vue.config.productionTip = false
    Vue.config.devtools = false
    Vue.config.debug = false
    Vue.config.silent = true
  }
}

const init = (data = {}) => {
  window.LastXSession = Vue.$localStorage.get('x-session', '')
  window.CartPromiseQueue = new PromiseQueue({
    concurrency: 1,
    cooldown   : 0
  })

  DataStore.App.Config = mergeDeep((DataStore?.App?.Config ?? {}), (data?.Settings ?? {}))
  DataStore.App.Stores = (data?.Stores || []).sort(sortCompareAlphabetically('Name'))
  DataStore.App.Payments = data?.Payments || []
  DataStore.App.Cart.PaymentMethod = DataStore.App.Payments.find(payment => payment.Status)

  Vue.$localStorage.set('x-session', data?.Settings?.XSession || '')

  initI18nLanguages()
  initVue()
  setupBugsnag()
}

getSystemConfig()
