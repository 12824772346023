/**
 * Curries a function.
 *
 * Use recursion. If the number of provided arguments (args) is sufficient, call the passed function fn.
 * Otherwise, return a curried function fn that expects the rest of the arguments. If you want to curry a
 * function that accepts a variable number of arguments (a variadic function, e.g. Math.min()), you can
 * optionally pass the number of arguments to the second parameter arity.
 *
 * @example curry(Math.pow)(2)(10); // 1024
 * @example curry(Math.min, 3)(10)(50)(2); // 2
 *
 * @param fn
 * @param arity
 * @param args
 * @return {{new(...args: any[]): any} | ((...args: [undefined, undefined]) => any) | OmitThisParameter<function(*=, *=, ...[*])> | ((...args: any[]) => any) | (function(*=, *=, ...[*])) | ((...args: [undefined]) => any) | any | {new(...args: *[]): any} | ((...args: *[]) => any)}
 */
export const curry = (fn, arity = fn.length, ...args) => arity <= args.length ? fn(...args) : curry.bind(null, fn, arity, ...args)

/**
 * Creates a debounced function that delays invoking the provided function until at least ms milliseconds have elapsed since the last time it was invoked.
 *
 * Each time the debounced function is invoked, clear the current pending timeout with clearTimeout() and use setTimeout() to create
 * a new timeout that delays invoking the function until at least ms milliseconds has elapsed. Use Function.prototype.apply() to apply
 * the this context to the function and provide the necessary arguments. Omit the second argument, ms, to set the timeout at a default of 0 ms.
 *
 * @example
 * window.addEventListener(
 * 'resize',
 * debounce(() => {
 *     console.log(window.innerWidth);
 *     console.log(window.innerHeight);
 * }, 250)
 * ); // Will log the window dimensions at most every 250ms
 *
 * @param callback
 * @param wait
 * @return {Function}
 */
export const debounce = (callback, wait) => {
  let timeoutId = null
  return (...args) => {
    window.clearTimeout(timeoutId)
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args)
    }, wait)
  }
}

/**
 * Defers invoking a function until the current call stack has cleared.
 *
 * Use setTimeout() with a timeout of 1ms to add a new event to the browser event queue and allow the rendering engine to complete its work.
 * Use the spread (...) operator to supply the function with an arbitrary number of arguments.
 *
 * @example
 * / Example A:
 * defer(console.log, 'a'), console.log('b'); // logs 'b' then 'a'
 *
 * // Example B:
 * document.querySelector('#someElement').innerHTML = 'Hello';
 * longRunningFunction(); // Browser will not update the HTML until this has finished
 * defer(longRunningFunction); // Browser will update the HTML then run the function
 *
 * @param fn
 * @param args
 * @return {number}
 */
export const defer = (fn, ...args) => setTimeout(fn, 1, ...args)

/**
 * Invokes the provided function after wait milliseconds.
 *
 * Use setTimeout() to delay execution of fn. Use the spread (...) operator to supply the function with an arbitrary number of arguments.
 *
 * @example
 * delay(
 * function(text) {
 *     console.log(text);
 * },
 * 1000,
 * 'later'
 * ); // Logs 'later' after one second.
 *
 * @param fn
 * @param wait
 * @param args
 * @return {number}
 */
export const delay = (fn, wait, ...args) => setTimeout(fn, wait, ...args)

/**
 * Returns the memoized (cached) function.
 *
 * Create an empty cache by instantiating a new Map object.
 * Return a function which takes a single argument to be supplied to the memoized function
 * by first checking if the function's output for that specific input value is already cached,
 * or store and return it if not. The function keyword must be used in order to allow the
 * memoized function to have its this context changed if necessary. Allow access to the cache
 * by setting it as a property on the returned function.
 *
 * @example
 * const anagramsCached = memoize(anagrams);
 * anagramsCached('javascript'); // takes a long time
 * anagramsCached('javascript'); // returns virtually instantly since it's now cached
 * console.log(anagramsCached.cache); // The cached anagrams map
 *
 * @param fn
 * @return {function(*=): (Map<any, any> | any)}
 */
export const memoize = fn => {
  const cache = new Map()
  const cached = function (val) {
    return cache.has(val) ? cache.get(val) : cache.set(val, fn.call(this, val)) && cache.get(val)
  }
  cached.cache = cache
  return cached
}

/**
 * Returns the index of the function in an array of functions which executed the fastest.
 *
 * Use Array.prototype.map() to generate an array where each value is the total time taken to execute the function after iterations times.
 * Use the difference in performance.now() values before and after to get the total time in milliseconds to a high degree of accuracy.
 * Use Math.min() to find the minimum execution time, and return the index of that shortest time which corresponds to the index of the
 * most performant function. Omit the second argument, iterations, to use a default of 10,000 iterations. The more iterations,
 * the more reliable the result but the longer it will take.
 *
 * @example
 * mostPerformant([
 *  () => {
 *     // Loops through the entire array before returning `false`
 *     [1, 2, 3, 4, 5, 6, 7, 8, 9, '10'].every(el => typeof el === 'number');
 *   },
 *  () => {
 *     // Only needs to reach index `1` before returning false
 *     [1, '2', 3, 4, 5, 6, 7, 8, 9, 10].every(el => typeof el === 'number');
 *   }
 * ]); // 1
 *
 * @param fns
 * @param iterations
 * @returns {*}
 */
export const mostPerformant = (fns, iterations = 10000) => {
  const times = fns.map(fn => {
    const before = performance.now()
    for (let i = 0; i < iterations; i++) fn()
    return performance.now() - before
  })
  return times.indexOf(Math.min(...times))
}

/**
 * Ensures a function is called only once.
 *
 * Utilizing a closure, use a flag, called, and set it to true once the function is called for the first time,
 * preventing it from being called again. In order to allow the function to have its this context changed
 * (such as in an event listener), the function keyword must be used, and the supplied function must have
 * the context applied. Allow the function to be supplied with an arbitrary number of arguments using the
 * rest/spread (...) operator.
 *
 * @example
 * const startApp = function(event) {
 * console.log(this, event); // document.body, MouseEvent
 * };
 * document.body.addEventListener('click', once(startApp)); // only runs `startApp` once upon click
 *
 * @param fn
 * @return {function(...[*]=): *}
 */
export const once = fn => {
  let called = false
  return function (...args) {
    if (called) return
    called = true
    return fn.apply(this, args)
  }
}

/**
 * Converts an asynchronous function to return a promise.
 *
 * Use currying to return a function returning a Promise that calls the original function. Use the ...rest operator to pass in all the parameters.
 *
 * NOTE: In Node 8+, you can use util.promisify
 *
 * @example
 * const delay = promisify((d, cb) => setTimeout(cb, d));
 * delay(2000).then(() => console.log('Hi!')); // // Promise resolves after 2s
 *
 * @param func
 * @returns {function(...[*]): Promise<unknown>}
 */
export const promisify = func => (...args) =>
  new Promise((resolve, reject) =>
    func(...args, (err, result) => (err ? reject(err) : resolve(result)))
  )

/**
 * Runs an array of promises in series.
 *
 * Use Array.prototype.reduce() to create a promise chain, where each promise returns the next promise when resolved.
 *
 * @example
 * const delay = d => new Promise(r => setTimeout(r, d));
 * runPromisesInSeries([() => delay(1000), () => delay(2000)]); // Executes each promise sequentially, taking a total of 3 seconds to complete
 *
 * @param ps
 * @return {*}
 */
export const runPromisesInSeries = ps => ps.reduce((p, next) => p.then(next), Promise.resolve())

/**
 * Delays the execution of an asynchronous function.
 *
 * Delay executing part of an async function, by putting it to sleep, returning a Promise.
 *
 * @example
 * async function sleepyWork() {
 *    console.log('I\'m going to sleep for 1 second.');
 *    await sleep(1000);
 *    console.log('I woke up after 1 second.');
 * }
 *
 *
 * @param ms
 * @return {Promise<unknown>}
 */
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

/**
 * Creates a throttled function that only invokes the provided function at most once per every wait milliseconds
 *
 * Use setTimeout() and clearTimeout() to throttle the given method, fn. Use Function.prototype.apply() to apply
 * the this context to the function and provide the necessary arguments. Use Date.now() to keep track of the last
 * time the throttled function was invoked. Omit the second argument, wait, to set the timeout at a default of 0 ms.
 *
 * @example
 * window.addEventListener(
 * 'resize',
 * throttle(function(evt) {
 *     console.log(window.innerWidth);
 *     console.log(window.innerHeight);
 * }, 250)
 * ); // Will log the window dimensions at most every 250ms
 *
 * @param fn
 * @param wait
 * @return {Function}
 */
export const throttle = (fn, wait) => {
  let inThrottle, lastFn, lastTime
  return function () {
    const context = this
    const args = arguments
    if (!inThrottle) {
      fn.apply(context, args)
      lastTime = Date.now()
      inThrottle = true
    } else {
      clearTimeout(lastFn)
      lastFn = setTimeout(function () {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args)
          lastTime = Date.now()
        }
      }, Math.max(wait - (Date.now() - lastTime), 0))
    }
  }
}

/**
 * Iterates over a callback n times
 *
 * Use Function.call() to call fn n times or until it returns false. Omit the last argument,
 * context, to use an undefined object (or the global object in non-strict mode).
 *
 * @example
 * var output = '';
 * times(5, i => (output += i));
 * console.log(output); // 01234
 *
 * @param n
 * @param fn
 * @param context
 */
export const times = (n, fn, context = undefined) => {
  let i = 0
  // eslint-disable-next-line no-empty
  while (fn.call(context, i) !== false && ++i < n) {
  }
}

/**
 * Measures the time taken by a function to execute.
 *
 * Use console.time() and console.timeEnd() to measure the difference between the start and end times
 * to determine how long the callback took to execute.
 *
 * @example timeTaken(() => Math.pow(2, 10)); // 1024, (logged): timeTaken: 0.02099609375ms
 *
 * @param callback
 * @returns {*}
 */
export const timeTaken = callback => {
  // eslint-disable-next-line no-console
  console.time('timeTaken')
  const r = callback()
  // eslint-disable-next-line no-console
  console.timeEnd('timeTaken')
  return r
}

/**
 * Tests a value, x, against a predicate function. If true, return fn(x). Else, return x.
 *
 * Return a function expecting a single value, x, that returns the appropriate value based on pred.
 *
 * @example
 * const doubleEvenNumbers = when(x => x % 2 === 0, x => x * 2);
 * doubleEvenNumbers(2); // 4
 * doubleEvenNumbers(1); // 1
 *
 * @param pred
 * @param whenTrue
 * @return {function(*=): *}
 */
export const when = (pred, whenTrue) => x => (pred(x) ? whenTrue(x) : x)
