<template>
  <v-hover v-slot="data">
    <v-card
      :disabled="storeIsDisabled(item)"
      :to="to"
    >
      <v-img
        v-if="item.Image"
        :class="hover && data.hover ? 'hover-zoom hover-zoom-active' : 'hover-zoom'"
        :height="dense ? 80 : 250"
        :src="item.Image"
        :style="{filter: (storeIsDisabled(item) ? 'grayscale(1)' : 'grayscale(0)')}"
      >
        <v-avatar
          class="ml-2 mt-2"
          color="white"
          size="64"
        >
          <img
            :src="item.Logo"
            style="padding: 2px;"
          >
        </v-avatar>

        <div
          v-if="dense"
          class="float-end mr-1"
        >
          <v-chip
            v-if="storeIsOpened(item)"
            :x-small="dense"
            class="px-1"
            color="brand-primary"
            dark
            label
          >
            {{ storeDefaultTime(item) }}
          </v-chip>

          <v-chip
            v-else
            :x-small="dense"
            class="px-1"
            color="red"
            dark
            label
          >
            {{ $t('Store.Status.Closed').toLocaleUpperCase($i18n.locale) }}
          </v-chip>
        </div>
      </v-img>

      <v-container
        :class="{'py-1 px-2': dense}"
        fluid
      >
        <v-row
          :no-gutters="dense"
          class="align-center"
        >
          <v-col :cols="storeDefaultTime(item) && !dense ? 8 : 12">
            <v-card-title
              v-if="item.Name"
              :class="{'pb-0': !item.Description || dense}"
              class="px-0 pt-0"
            >
              <div
                class="overflow-hidden text-no-wrap"
                style="text-overflow: ellipsis;"
              >
                {{ item.Name }}
              </div>
            </v-card-title>

            <v-card-subtitle
              v-if="item.Description && !dense"
              class="px-0 pb-0"
            >
              <div
                class="overflow-hidden text-no-wrap"
                style="text-overflow: ellipsis;"
              >
                {{ item.Description }}
              </div>
            </v-card-subtitle>
          </v-col>

          <v-col
            v-if="!dense"
            class="text-right"
          >
            <v-chip
              v-if="storeIsOpened(item)"
              :small="dense"
              class="px-2"
              color="brand-primary"
              dark
              label
            >
              {{ storeDefaultTime(item) }}
            </v-chip>

            <v-chip
              v-else
              :small="dense"
              class="px-2"
              color="red"
              dark
              label
            >
              {{ $t('Store.Status.Closed').toLocaleUpperCase($i18n.locale) }}
            </v-chip>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-hover>
</template>

<script>

import StoreCommon from '@/mixins/store/storeCommon'

export default {
  name      : 'StoreCard',
  components: {},
  directives: {},
  mixins    : [StoreCommon],
  props     : {
    item: {
      type   : Object,
      default: undefined
    },

    to: {
      type   : Object,
      default: undefined
    },

    hover: {
      type   : Boolean,
      default: false
    },

    dense: {
      type   : Boolean,
      default: false
    }

  },
  enums: {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>
.hover-zoom {
  overflow: hidden;
}

/deep/ .hover-zoom .v-image__image {
  transition: all 0.3s;
}

/deep/ .hover-zoom-active .v-image__image {
  transform: scale(1.1);
}
</style>
