<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="650"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar>
            <v-icon x-large>
              mdi-store-search
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Store.SelectStoreDialog.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Store.SelectStoreDialog.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            class="grey lighten-3 ma-0"
            color="black"
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-0">
          <v-container fluid>
            <v-row dense>
              <v-col cols="12">
                <v-list
                  :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4'"
                  class="pa-0"
                >
                  <template v-for="(store, idx) in stores">
                    <v-list-item
                      v-if="!storeIsHidden(store)"
                      :key="`list-item-${idx}`"
                      :disabled="storeIsDisabled(store)"
                      ripple
                      @click="onStoreSelected(store)"
                    >
                      <v-list-item-avatar
                        class="rounded"
                        color="white"
                        size="64"
                        tile
                      >
                        <img
                          v-if="store.Logo"
                          :src="store.Logo"
                          :style="{filter: (storeIsDisabled(store) ? 'grayscale(1)' : 'grayscale(0)')}"
                        >
                      </v-list-item-avatar>

                      <v-list-item-content class="grey--text text--darken-2">
                        <v-list-item-title class="text-left">
                          <h3>{{ store.Name }}</h3>
                        </v-list-item-title>

                        <v-list-item-subtitle v-if="store.Desc">
                          <span class="grey--text">
                            {{ store.Desc }}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-chip
                          v-if="storeIsOpened(store)"
                          color="brand-primary"
                          dark
                          label
                          small
                        >
                          {{ storeDefaultTime(store) }}
                        </v-chip>

                        <v-chip
                          v-else
                          color="red"
                          dark
                          label
                          small
                        >
                          {{ $t('Store.Status.Closed').toLocaleUpperCase($i18n.locale) }}
                        </v-chip>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider
                      v-if="!storeIsHidden(store)"
                      :key="`list-item-${idx}-divider`"
                    />
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import DialogVisible from '@/mixins/dialog/dialogVisible'
import StoreCommon from '@/mixins/store/storeCommon'

export default {
  name      : 'SelectStoreDialog',
  components: {},
  mixins    : [DialogVisible, StoreCommon],
  props     : {
    stores: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {}
  },
  computed: {},
  methods : {
    onStoreSelected (store) {
      this.isVisible = false
      this.$emit('store:selected', store)
    },

    onCancelButtonClick () {
      this.isVisible = false
    }
  }
}
</script>
