<template>
  <v-app-bar
    :color="$vuetify.theme.dark ? 'grey darken-4' : 'white'"
    :height="height"
    :style="$vuetify.breakpoint.lgAndUp ? 'padding-left: 8.3333333333%; padding-right: 8.3333333333%;' : ''"
    app
    clipped-right
    style="box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.12);"
  >
    <div
      v-if="logo"
      class="d-flex align-center fill-height"
    >
      <router-link
        :to="{name: 'Home'}"
        style="line-height: 0"
      >
        <img
          :alt="$t('Common.App.Title')"
          :src="$vuetify.theme.dark ? '/img/logo/logo-light.png' : '/img/logo/logo-dark.png'"
          height="28"
        >
      </router-link>
    </div>

    <div class="flex-grow-1" />

    <template v-if="account">
      <template v-if="userIsAuthenticated">
        <v-menu
          v-model="accountMenu"
          max-width="400"
          offset-y
        >
          <template #activator="{on, attrs}">
            <v-btn
              :disabled="isAuthLoading"
              :loading="isAuthLoading"
              class="text-capitalize ml-1 pa-1"
              depressed
              large
              v-bind="attrs"
              v-on="on"
            >
              <v-avatar
                class="white--text rounded"
                color="brand-primary"
                left
                size="32"
                tile
              >
                {{ userInitials }}
              </v-avatar>

              <v-icon right>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-list class="px-2">
              <v-list-item :to="{name: 'Account'}">
                <v-avatar
                  class="white--text rounded mr-4"
                  color="brand-primary"
                  size="40"
                  tile
                >
                  {{ userInitials }}
                </v-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ user.Info.Firstname }} {{ user.Info.Lastname }}</v-list-item-title>
                  <v-list-item-subtitle class="caption">
                    {{ $t('Auth.AccountDialog.MemberSince') }} {{
                      user.Info.DateAdded
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon>
                    mdi-chevron-right
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-divider />

            <!--
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-switch color="purple" />
                </v-list-item-action>
                <v-list-item-title>Enable messages</v-list-item-title>
              </v-list-item>
            </v-list>
            -->

            <v-card-actions>
              <v-spacer />

              <v-btn
                :disabled="isAuthLoading"
                :loading="isAuthLoading"
                class="text-none white--text"
                color="brand-primary"
                depressed
                @click="logout"
              >
                {{ toLocaleTitleCase($t('Auth.Button.Logout')) }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>

      <template v-else>
        <v-btn
          class="text-capitalize ml-1 px-2"
          large
          text
          @click="onLoginClick"
        >
          <template v-if="AppConfig.Toolbar.ShowRegisterButton">
            {{ $t('Auth.Button.Login') }}
          </template>

          <template v-else>
            {{ $t('Auth.Button.Login') }} / {{ $t('Auth.Button.Register') }}
          </template>
        </v-btn>

        <v-btn
          v-if="AppConfig.Toolbar.ShowRegisterButton"
          class="text-capitalize ml-1"
          color="brand-primary"
          dark
          depressed
          large
          @click="onRegisterClick"
        >
          {{ $t('Auth.Button.Register') }}
        </v-btn>
      </template>
    </template>

    <template v-if="cart">
      <v-btn
        v-if="cartProductsCount && $vuetify.breakpoint.smAndUp"
        class="text-capitalize ml-1 pl-3 pr-4"
        color="brand-primary"
        dark
        depressed
        large
        @click.stop="navigationDrawerVisible = !navigationDrawerVisible"
      >
        <v-badge
          class="mt-2 mr-4"
          color="white"
          overlap
        >
          <template #badge>
            <span class="brand-primary--text">{{ cartProductsCount }}</span>
          </template>

          <v-icon>mdi-cart</v-icon>
        </v-badge>

        {{ $t('Cart.Button.ViewCart') }}

        <span class="ml-4">{{ orderTotal | currency }}</span>
      </v-btn>

      <v-btn
        v-if="cartProductsCount && $vuetify.breakpoint.xsOnly"
        class="ml-1"
        color="brand-primary"
        dark
        depressed
        icon
        large
        @click.stop="navigationDrawerVisible = !navigationDrawerVisible"
      >
        <v-badge
          class="mr-2"
          color="brand-primary"
          overlap
        >
          <template #badge>
            <span class="white--text">{{ cartProductsCount }}</span>
          </template>

          <v-icon>mdi-cart</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <lang-switch
      v-if="langSwitch"
      class="ml-2"
    />

    <template
      v-if="AppConfig.Toolbar.AppToolbarExtended"
      #extension
    >
      <slot name="extension-prepend" />
      <slot name="extension">
        <portal-target
          name="AppToolbarExtension"
          slim
        />
      </slot>
      <slot name="extension-append" />
    </template>
  </v-app-bar>
</template>

<script>

import { floatNumber, toLocaleLowerCase, toLocaleTitleCase } from '@/lib/helpers'
import LangSwitch from '@/theme/default/components/widgets/LangSwitch.vue'
import CartCommon from '@/mixins/cart/cartCommon'

export default {
  name      : 'AppToolbar',
  components: { LangSwitch },
  directives: {},
  mixins    : [CartCommon],
  props     : {
    navigationDrawer: {
      type   : Boolean,
      default: undefined
    },
    logo: {
      type   : Boolean,
      default: true
    },
    langSwitch: {
      type   : Boolean,
      default: false
    },
    cart: {
      type   : Boolean,
      default: false
    },
    account: {
      type   : Boolean,
      default: false
    },
    height: {
      type   : [String, Number],
      default: 68
    }
  },
  dataStore: {
    AppCart: 'App.Cart'
  },
  data () {
    return {
      accountMenu    : null,
      userMenuVisible: false
    }
  },
  computed: {
    title () {
      const key = `Route.${this.$route.name}.Title`
      return this.$te(key) ? this.$t(key) : this.$t('Common.App.Title')
    },

    navigationDrawerVisible: {
      get () {
        return this.navigationDrawer
      },
      set (val) {
        this.$emit('update:navigationDrawer', val)
      }
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    toLocaleTitleCase,
    toLocaleLowerCase,
    floatNumber,

    onLoginClick () {
      this.$bus.$emit('login:show-dialog')
    },

    onLogoutClick () {
      this.logout()
    },

    onRegisterClick () {
      this.$bus.$emit('register:show-dialog')
    }
  }
}
</script>

<style scoped>
/deep/ .v-toolbar__content {
  padding-top: 0;
  padding-bottom: 0;
  height: 100% !important;
}
</style>
