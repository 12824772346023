export default {
  Button: {
    Login         : 'Login',
    Logout        : 'Logout',
    Register      : 'Signup',
    ForgotPassword: 'I forgot my password',
    ResetPassword : 'Reset Password',
    NoAccount     : 'You do not have an account; Sign up with Email!',
    WithAccount   : 'Already have an account? Log In!'
  },

  Login: {
    Success: 'You have successfully logged in.'
  },

  Logout: {
    Success: 'You have successfully logged out.'
  },

  AccountDialog: {
    MemberSince: 'Member Since'
  },

  LoginDialog: {
    Title   : 'Login',
    Subtitle: 'Login below',
    Social  : {
      Title   : 'Sign in with your social account',
      Google  : 'Login with Google',
      Facebook: 'Login with Facebook'
    },
    Account: {
      Title: 'or login in with your email',
      Field: {
        Email: {
          Label      : 'Email Address',
          Hint       : '',
          Placeholder: 'Email Address'
        },
        Password: {
          Label      : 'Password',
          Hint       : '',
          Placeholder: 'Password'
        }
      }
    }
  },

  ResetPasswordDialog: {
    Title   : 'Password Remind',
    Subtitle: 'You will receive an email with a new password.',
    Field   : {
      Email: {
        Label      : 'Email',
        Hint       : '',
        Placeholder: 'Email'
      }
    }
  },

  RegisterDialog: {
    Title   : 'Sign up',
    Subtitle: 'Sign up with Email',
    Field   : {
      Firstname: {
        Label      : 'Firstname',
        Hint       : '',
        Placeholder: 'Firstname'
      },
      Lastname: {
        Label      : 'Lastname',
        Hint       : '',
        Placeholder: 'Lastname'
      },
      Email: {
        Label      : 'Email Address',
        Hint       : '',
        Placeholder: 'Email Address'
      },
      Phone: {
        Label      : 'Mobile Phone',
        Hint       : '',
        Placeholder: 'Mobile Phone'
      },
      Password: {
        Label      : 'Password',
        Hint       : '',
        Placeholder: 'Password'
      },
      PasswordConfirm: {
        Label      : 'Confirm Password',
        Hint       : '',
        Placeholder: 'Confirm Password'
      },
      TermsCondition: {
        Label: 'I agree with the',
        Link : 'terms & conditions'
      }
    }
  }
}
