import OrderStatusEnum from '@/api/enums/OrderStatusEnum'

export default {
  Title: 'Η Παραγγελία σας',

  Button: {
    ViewCart   : 'Προβολή Παραγγελίας',
    Checkout   : 'Ολοκλήρωση Παραγγελίας',
    AddItems   : 'Προσθήκη Προϊόντων',
    UpdateOrder: 'Ενημέρωση',
    AddToOrder : 'Προσθήκη'
  },

  Coupon: {
    Title : 'Έχετε εκπτωτικό κουπόνι;',
    Label : 'Κωδικός Κουπονιού',
    Item  : 'ΚΟΥΠΟΝΙ',
    Button: {
      Apply : 'Εφαρμογή Κουπονιού',
      Remove: 'Αφαίρεση Κουπονιού'
    }
  },

  BrandSlider: {
    Title: 'Συμπλήρωσε την παραγγελία σου'
  },

  OrderStatus: [
    {
      Id   : OrderStatusEnum.NotComplete,
      Name : 'ΜΗ ΟΛΟΚΛΗΡΩΜΕΝΗ',
      Color: 'deep-orange',
      Icon : 'mdi-priority-high'
    },
    {
      Id   : OrderStatusEnum.Pending,
      Name : 'ΣΕ ΑΝΑΜΟΝΗ',
      Color: 'grey',
      Icon : 'mdi-swap-vertical-circle'
    },
    {
      Id   : OrderStatusEnum.Received,
      Name : 'ΠΡΟΣ ΑΠΟΔΟΧΗ',
      Color: 'grey',
      Icon : 'mdi-call-received'
    },
    {
      Id   : OrderStatusEnum.Accepted,
      Name : 'ΑΠΟΔΕΚΤΗ',
      Color: 'green',
      Icon : 'mdi-check'
    },
    {
      Id   : OrderStatusEnum.Preparation,
      Name : 'ΠΡΟΕΤΟΙΜΑΣΊΑ',
      Color: 'purple',
      Icon : 'mdi-room-service'
    },
    {
      Id   : OrderStatusEnum.Delivery,
      Name : 'ΠΡΟΣ ΠΑΡΑΛΑΒΗ',
      Color: 'brown',
      Icon : 'mdi-run'
    },
    {
      Id   : OrderStatusEnum.Completed,
      Name : 'ΟΛΟΚΛΗΡΩΜΕΝΗ',
      Color: 'light-green',
      Icon : 'mdi-check-all'
    },
    {
      Id   : OrderStatusEnum.Canceled,
      Name : 'ΑΚΥΡΩΜΕΝΗ',
      Color: 'red',
      Icon : 'mdi-close'
    }
  ],

  Message: {
    Info: {
      Security    : 'Ασφάλεια Συναλλαγών με 128-bit Κρυπτογράφηση.',
      MinimumOrder: {
        Body   : 'Χρειάζεστε {minimumOrderTotalDiff} ακόμα για την ελάχιστη παραγγελία. Παρακαλώ προσθέστε περισσότερα προϊόντα στο καλάθι σας.',
        BodyAlt: 'Χρειάζεσαι {minimumOrderTotalDiff} ακόμα για την ελάχιστη παραγγελία.',
        Title  : 'Ελάχιστη Παραγγελία'
      },
      NoItems: {
        Title   : 'Δεν υπάρχουν προϊόντα στο καλάθι σας',
        Subtitle: 'Προσθέστε προϊόντα και δημιουργήστε μια παραγγελία!'

      }
    },
    Error: {
      Product: {
        Option: {
          Between: {
            Message: 'Παρακαλώ κάντε από {MinChoices} έως {MaxChoices} επιλογές | Παρακαλώ κάντε από {MinChoices} έως {MaxChoices} επιλογή | Παρακαλώ κάντε από {MinChoices} έως {MaxChoices} επιλογές'
          },
          MaxChoices: {
            Message: 'Παρακαλώ κάντε μέχρι {MaxChoices} επιλογές | Παρακαλώ κάντε μέχρι {MaxChoices} επιλογή | Παρακαλώ κάντε μέχρι {MaxChoices} επιλογές'
          },
          MinChoices: {
            Message: 'Παρακαλώ κάντε {MinChoices} επιλογές | Παρακαλώ κάντε {MinChoices} επιλογή | Παρακαλώ κάντε {MinChoices} επιλογές'
          },
          MinChoicesRequired: {
            Message: 'Παρακαλώ κάντε τουλάχιστον {MinChoices} επιλογές | Παρακαλώ κάντε τουλάχιστον {MinChoices} επιλογή | Παρακαλώ κάντε τουλάχιστον {MinChoices} επιλογές'
          },
          MinimumQuantity: {
            Body : 'Το απόθεμα για το προϊόν «{menuItem}» είναι μικρότερο από την ελάχιστη ποσότητα ({minimumQuantity}) παραγγελίας.',
            Title: ''
          }
        },
        Stock: {
          Invalid: {
            Body : 'Δεν υπάρχει διαθέσιμο απόθεμα για το προϊόν «{menuItem}».',
            Title: ''
          },
          MinimumQuantity: {
            Body : 'Το απόθεμα για το προϊόν «{menuItem}» είναι μικρότερο από την ελάχιστη ποσότητα ({minimumQuantity}) παραγγελίας.',
            Title: ''
          }
        }
      }
    }
  }
}
